.custom-button {
  background: linear-gradient(to right, #4caf50, #388e3c); /* Adjust colors if needed */
  border: none;
  transition: background 0.3s;
}

.custom-button:hover,
.custom-button:focus,
.custom-button:active {
  background: linear-gradient(to right, #4caf50, #388e3c); /* Keep the same gradient on hover, focus, and active */
  border: none;
  outline: none;
}
.font-style{
  font-family: "Plus Jakarta Sans";
}

.ant-notification-notice {
  font-family: "Plus Jakarta Sans";
}

/* Customize the space between date cells */


.placeholder-custom::placeholder {
  color: #5A5555; /* Placeholder text color */
  font-size: 14px; /* Placeholder font size */
  font-family: 'Plus Jakarta Sans'; /* Placeholder font family */
  font-weight: 400; /* Placeholder font weight */
  line-height: 20px; /* Placeholder line height */
  word-wrap: break-word; /* Ensure long words break */
}


*::-webkit-scrollbar {
  height: 8px;
  width: 5px;
}

*::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

*::-webkit-scrollbar-track:active {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #E4E4EA;
}


/* *::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color);
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--primary-color);
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  transition: background-color 5000s ease-in-out 0s;
  /* box-shadow: inset 0 0 20px 20px #23232329; */
}


.event-delivery {
  @apply bg-yellow-400 text-white rounded-full w-10 h-10 flex items-center justify-center;
}

.event-vaccination {
  @apply border-2 border-green-500 rounded-full w-10 h-10 flex items-center justify-center;
}

.event-milk-checking {
  @apply border-2 border-blue-500 rounded-full w-10 h-10 flex items-center justify-center;
}

/* Highlight current day */
.react-calendar__tile--now {
  @apply border-2 border-orange-400 text-black font-bold rounded-full;
}

/* Active day styling */
.react-calendar__tile--active {
  @apply bg-yellow-400 text-white rounded-full;
}

/* Calendar navigation buttons */
.react-calendar__navigation button {
  @apply text-black text-lg font-semibold;
}

/* Calendar general styling */
.react-calendar {
  @apply w-[400px] bg-white rounded-lg shadow-lg;
}
.custom-modal {
  max-width: 900px; /* Set the desired width here */
  width: 100%; /* Ensures the modal adapts to smaller screens */
}

@media (max-width: 768px) {
  .custom-modal {
      max-width: 100%; /* Make sure it's responsive on small screens */
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
html, body {
  zoom: 100%;
  -webkit-text-size-adjust: 100%;
}


@media (-webkit-device-pixel-ratio: 1.25) {
  #root {
   
  }
  .xxl-font{
    font-size: 18px;
  }
}

.formheading{
  @apply text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans
}
.formvalue{
  @apply text-[#0A0B0A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans
}