@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: Graphik;
  src: url("./assets/Fonts/GraphikRegular.otf") format("opentype");
}

@font-face {
  font-family: Inter;
  src: url("./assets/Fonts/Inter-Regular.ttf") format("opentype");
}

@font-face {
  font-family: Poppins;
  src: url("./assets/Fonts/Poppins-Regular.ttf") format("opentype");
} */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}



/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* p {
  @apply leading-[1.8] text-white/60 font-light;
} */
.event-delivery {
  @apply bg-yellow-400 text-white rounded-full w-10 h-10 flex items-center justify-center;
}

.event-vaccination {
  @apply border-2 border-green-500 rounded-full w-10 h-10 flex items-center justify-center;
}

.event-milk-checking {
  @apply border-2 border-blue-500 rounded-full w-10 h-10 flex items-center justify-center;
}

/* Highlight current day */
@layer components {
  .custom-calendar .ant-picker-cell {
    @apply p-1; /* Apply smaller padding to the cells */
  }

  .custom-calendar .ant-picker-cell-inner {
    @apply border-0; /* Remove dividers between the cells */
  }

  .custom-calendar .ant-picker-cell.ant-picker-cell-selected .ant-picker-cell-inner {
    @apply bg-transparent; /* Remove default background for selected days */
  }
}

/* Day tile hover effect */
.day-tile:hover {
  background-color: #f1f1f1;
  cursor: pointer;
  transform: scale(1.05);
}

/* Navigation buttons */
.nav-button {
  color: #333;
  font-weight: bold;
  transition: background-color 0.2s;
}

.nav-button:hover {
  background-color: #ddd;
}

/* Transition for hover */
.custom-calendar:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
}

/* Weekday label */
.weekday {
  color: #888;
  font-size: 14px;
}
.react-calendar{
  width: 520px;
}
.ant-spin-dot-item {
  background-color: #16A34A !important;
}

